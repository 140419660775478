import React from 'react'
import SideNavigation from "../layout/Sidebar";
import {Dropdown, Grid} from "semantic-ui-react";
import {connect} from "react-redux";
import {getHomeSafeSessions, getSessionInfo, getUsers} from "./homeSafeActions";
import MapContainer from "./MapContainer";


class HomeSafeContainer extends React.Component {
    state = {
        userValue: null,
        sessionValue: null
    }

    componentDidMount() {
        this.props.getUsers()
    }

    handleChange = (e, { value }) => {
        this.props.getHomeSafeSessions(value)
        this.setState({ userValue: value })
    }

    handleSessionChange = (e, { value }) => {
        this.props.getSessionInfo(value)
        this.setState({ sessionValue: value })
    }

    render() {
        const { loading, users, sessions, sessionInfo } = this.props

        return <SideNavigation selected="homeSafeDebug">
            <Grid padded>
                <Grid.Row>
                    <Grid.Column>
                        <h1>HomeSafe</h1>
                        <p>
                            You can use the below to debug recent HomeSafe sessions.  Select a user and a session.
                        </p>
                    </Grid.Column>
                </Grid.Row>

                { loading && <div>Loading data, please wait...</div>}

                {loading || <Grid.Row>
                    <Grid.Column>
                        <Dropdown
                            placeholder='Select a user email'
                            fluid
                            search
                            selection
                            options={users}
                            onChange={this.handleChange}
                            value={this.state.userValue}
                        />

                        <Dropdown
                            style={{ marginTop: 20 }}
                            placeholder='Select a HomeSafe session'
                            fluid
                            search
                            selection
                            options={sessions}
                            onChange={this.handleSessionChange}
                            value={this.state.sessionValue}
                        />
                    </Grid.Column>
                </Grid.Row>}

                {
                    sessionInfo !== null && <React.Fragment>
                        <Grid.Row>
                            <Grid.Column>
                                <div>Session was requested: {new Date(sessionInfo.requestedOnUtc).toString()}</div>
                                <div>Due by: {new Date(sessionInfo.dueByUtc).toString()}</div>
                                <div>Pending activation: {sessionInfo.isPendingActivation ? 'yes' : 'no' }</div>
                                <div>Active: {sessionInfo.isActive ? 'yes' : 'no' }</div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                {sessionInfo.locationHistory.length > 0 && <MapContainer
                                    destination={sessionInfo.dueDestination}
                                    locations={sessionInfo.locationHistory}
                                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAx9W2KIABeKtxl2kEtfcQRe0oRGPRg2kY"
                                    loadingElement={<div style={{ height: `100%` }} />}
                                    containerElement={<div style={{ height: `1000px` }} />}
                                    mapElement={<div style={{ height: `100%` }} />}/>}

                                { sessionInfo.locationHistory.length  === 0 && <div>
                                    There is no location data for this HomeSafe entry
                                </div>}
                            </Grid.Column>
                        </Grid.Row>
                        </React.Fragment>
                }
            </Grid>
        </SideNavigation>
    }
}

const mapStateToProps = state => ({
     users: state.homeSafe.users.map(x => ({ key: x.id, value: x.id, text: x.email })),
     sessions: state.homeSafe.sessions.map(x => ({ key: x.id, value: x.id, text: `${x.id} started ${new Date(x.createdOnUtc).toString()}`})),
     loading: state.homeSafe.loading,
     sessionInfo: state.homeSafe.sessionInfo
});

const mapDispatchToProps = {
    getUsers,
    getHomeSafeSessions,
    getSessionInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeSafeContainer)
