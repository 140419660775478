import axios from 'axios'
import {baseUrl} from "../globals";
import {push} from "react-router-redux";

export const SET_LOADING = 'admin-portal/homeSafeDebug/SET_LOADING';
export const SET_USERS = 'admin-portal/homeSafeDebug/SET_USERS';
export const SET_HOMESAFE_SESSIONS = 'admin-portal/homeSafeDebug/SET_HOMESAFE_SESSIONS';
export const SET_HOMESAFE_SESSION_INFO = 'admin-portal/homeSafeDebug/SET_HOMESAFE_SESSION_INFO';

const setLoading = loading => ({
    type: SET_LOADING,
    loading
});

const setUsers = users => ({
    type: SET_USERS,
    users
})

const setHomeSafeSessions = sessions => ({
    type: SET_HOMESAFE_SESSIONS,
    sessions
})

const setSessionInfo = info => ({
    type: SET_HOMESAFE_SESSION_INFO,
    info
})

export const getUsers = () => dispatch => {
    dispatch(setLoading(true))

    return axios.get(baseUrl + 'api/admin/homesafe/users')
        .then(result => {
            dispatch(setLoading(false))
            dispatch(setUsers(result.data))
        })
        .catch(error => {
            dispatch(push('/login'))
            dispatch(setLoading(false))
            console.error(error)
        })
}

export const getHomeSafeSessions = userId => dispatch => {
    dispatch(setLoading(true))

    return axios.get(baseUrl + `api/admin/homesafe/sessions?userId=${userId}`)
        .then(result => {
            dispatch(setLoading(false))
            dispatch(setHomeSafeSessions(result.data))
        })
        .catch(error => {
            dispatch(push('/login'))
            dispatch(setLoading(false))
            console.error(error)
        })
}

export const getSessionInfo = sessionId => dispatch => {
    dispatch(setLoading(true))

    return axios.get(baseUrl + `api/admin/homesafe/info?sessionId=${sessionId}`)
        .then(result => {
            dispatch(setLoading(false))
            dispatch(setSessionInfo(result.data))
        })
        .catch(error => {
            dispatch(push('/login'))
            dispatch(setLoading(false))
            console.error(error)
        })
}
