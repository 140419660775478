import jwtDecode from "jwt-decode";

export const tokenHasExpired = token => {
  const jwt = jwtDecode(token);
  if (jwt.exp < Date.now() / 1000) {
    return true;
  }
  return false;
};

export const isAdminToken = token => {
  const decodedToken = jwtDecode(token);
  return decodedToken.role === 'Admin';
};

export const getSubFromToken = token =>
    jwtDecode(token).sub;
