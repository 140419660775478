import React from 'react'
import { createStore, combineReducers, applyMiddleware } from 'redux'
import { routerReducer, routerMiddleware } from 'react-router-redux'
import thunk from 'redux-thunk';
import loginReducer from "./login/loginReducer";
import pushHubReducer from "./pushHub/pushHubReducer";
import homeSafeReducer from "./homeSafeDebug/homeSafeReducer";


export default (history) => {
    return createStore(
        combineReducers({
            router: routerReducer,
            pushHub: pushHubReducer,
            auth: loginReducer,
            homeSafe: homeSafeReducer
        }),
        applyMiddleware(thunk, routerMiddleware(history))
    )
};
