import axios from 'axios';
import {push} from "react-router-redux";
import {isAdminToken} from "../helpers/tokenHelpers";
import {baseUrl} from "../globals";

export const SET_LOADING = "admin-portal/login/SET_LOADING";
export const LOGIN_SUCCESSFUL = "admin-portal/login/LOGIN_SUCCESSFUL";

export const setLoading = (loading) => ({
    type: SET_LOADING,
    loading
});

const postLogin = (username, password) => axios.post(baseUrl + 'api/login', {
    Username: username,
    Password: password
});

const postAppleSignin = (identityToken) => axios.post(baseUrl + "api/loginWithApple", {
    identityToken
});

const postGoogleSignin = (identityToken) => axios.post(baseUrl + "api/loginWithGoogle", {
    identityToken
});

const loginSuccessful = (accessToken) => ({
    type: LOGIN_SUCCESSFUL,
    accessToken
});

export const loginWithAppleIdentityToken = identityToken => dispatch => {
    return postAppleSignin(identityToken)
        .then(result => {
            if (result.status === 200) {
                const accessToken = result.data.accessToken;
                if (accessToken !== null) {
                    if (isAdminToken(accessToken)){
                        axios.defaults.headers.post['Authorization'] = `Bearer ${accessToken}`;
                        axios.defaults.headers.get['Authorization'] = `Bearer ${accessToken}`;
                        axios.defaults.headers.delete['Authorization'] = `Bearer ${accessToken}`;

                        dispatch(loginSuccessful(accessToken));
                        dispatch(push('/pushHub'))
                    }
                    else {
                        dispatch(push('error/not-admin'))
                    }
                }
            }
        })
}

export const loginWithGoogleIdentityToken = identityToken => dispatch => {
    return postGoogleSignin(identityToken)
        .then(result => {
            if (result.status === 200) {
                const accessToken = result.data.accessToken;
                if (accessToken !== null) {
                    if (isAdminToken(accessToken)){
                        axios.defaults.headers.post['Authorization'] = `Bearer ${accessToken}`;
                        axios.defaults.headers.get['Authorization'] = `Bearer ${accessToken}`;
                        axios.defaults.headers.delete['Authorization'] = `Bearer ${accessToken}`;

                        dispatch(loginSuccessful(accessToken));
                        dispatch(push('/pushHub'))
                    }
                    else {
                        dispatch(push('error/not-admin'))
                    }
                }
            }
        })
}

export const login = (email, password) => dispatch => {
    dispatch(setLoading(true));
    return postLogin(email, password)
        .then(result => {
            dispatch(setLoading(false));
            if (result.status === 200) {
                const accessToken = result.data.accessToken;
                if (accessToken !== null) {
                    if (isAdminToken(accessToken)){
                        axios.defaults.headers.post['Authorization'] = `Bearer ${accessToken}`;
                        axios.defaults.headers.get['Authorization'] = `Bearer ${accessToken}`;
                        axios.defaults.headers.delete['Authorization'] = `Bearer ${accessToken}`;

                        dispatch(loginSuccessful(accessToken));
                        dispatch(push('/pushHub'))
                    }
                }
            }
            else {
                throw new Error(0);
            }
        })
        .catch(error => dispatch(setLoading(false)))
};
