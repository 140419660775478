import axios from "axios";
import {baseUrl} from "../globals";

export const SET_LOADING = 'admin-portal/pushHub/SET_LOADING';
export const UPDATE_PUSH_MESSAGE = 'admin-portal/pushHub/UPDATE_PUSH_MESSAGE';
export const SET_USERS_IMPACTED = 'admin-portal/pushHub/SET_USERS_IMPACTED';

export const AppVersion = {
    WalkSafePlus: 2,
    WalkSafe: 1
}

export const updatePushMessage = message => ({
    type: UPDATE_PUSH_MESSAGE,
    message
});

export const setLoading = loading => ({
    type: SET_LOADING,
    loading
});

export const setUsersImpacted = userCount => ({
    type: SET_USERS_IMPACTED,
    count: userCount
})

const postPushNotification = (message, appSegment) => axios.post(baseUrl + 'api/admin/pushHub', {
    Message: message,
    AppSegment: appSegment
});

const postEstimateUsers = appVersion => axios.post(baseUrl + 'api/admin/pushHub/estimateReceivingUsers', {
    AppSegment: appVersion
});

export const sendPushNotification = (message, appSegment) => dispatch => {
    dispatch(setLoading(true));

    return postPushNotification(message, appSegment)
        .then(result => {
            dispatch(setLoading(false));
            dispatch(updatePushMessage(''));
        })
        .catch(error => {
            dispatch(setLoading(false));
            console.error(error)
        });
};

export const estimateImpactedUsers = (appVersion) => dispatch => {
    return postEstimateUsers(appVersion).then(res => {
            dispatch(setUsersImpacted(res.data.users))
    })
}
