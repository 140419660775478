import axios from "axios";
import {baseUrl} from "../globals";

export const deleteUser = email => dispatch => {
    return axios({
        method: 'delete',
        url: baseUrl + 'api/admin/user',
        data: {
            email: email
        }
    })

}
