import React, { Component } from 'react';
import { Provider } from 'react-redux'
import { Route, Redirect } from 'react-router';
import { ConnectedRouter } from 'react-router-redux'
import createHistory from 'history/createBrowserHistory'
import styled from 'styled-components';

import LoginContainer from "./login/LoginContainer";
import configureStore from "./configureStore";

import NotificationsContainer from "./pushHub/PushHubContainer";
import HomeSafeContainer from "./homeSafeDebug/HomeSafeContainer";
import UserManagementContainer from "./userManagement/UserManagementContainer";
import AppleSigninContainer from "./login/AppleSigninContainer";
import GoogleSigninContainer from "./login/GoogleSigninContainer";
//import {attachSignalR} from "./signalR";


const history = createHistory();
const store = configureStore(history);

//attachSignalR(store);

const FullHeightDiv = styled.div`height:100%`;

class App extends Component {
  render() {
    return <Provider store={store}>
      <ConnectedRouter history={history}>
          <FullHeightDiv>

            <Route path="/login" component={LoginContainer}/>
            <Route path="/pushHub" component={NotificationsContainer}/>
            <Route path="/homeSafeDebug" component={HomeSafeContainer}/>
            <Route path="/userManagement" component={UserManagementContainer}/>

            <Route path="/auth/apple-signin" component={AppleSigninContainer}/>
            <Route path="/auth/google-signin" component={GoogleSigninContainer}/>

            <Route path="/" exact render={() => (<Redirect to="/login"/>)}/>
            <Route path="/error/not-admin" exact render={() => (<div>You need to be an administrator to use this system</div>)}/>
          </FullHeightDiv>
      </ConnectedRouter>
    </Provider>
  }
}

export default App;
