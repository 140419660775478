import * as actions from "./homeSafeActions";

const initialState = {
    loading: false,
    users: [],
    sessions: [],
    sessionInfo: null
};

export default function reducer(state = initialState, action = {}) {

    console.log(action)
    switch (action.type) {
        case actions.SET_LOADING:
            return {
                ...state,
                loading: action.loading
            };

        case actions.SET_USERS:
            return {
                ...state,
                users: action.users
            };

        case actions.SET_HOMESAFE_SESSIONS:
            return {
                ...state,
                sessions: action.sessions
            }

        case actions.SET_HOMESAFE_SESSION_INFO:
            return {
                ...state,
                sessionInfo: action.info
            }

        default:
            return state;
    }
}
