import React from "react";
import {loginWithAppleIdentityToken} from "./loginActions";
import {connect} from "react-redux";
import queryString from 'query-string';

class AppleSigninContainer extends React.Component {
    componentDidMount() {
        const query = queryString.parse(this.props.location.search);
        const identityToken = query.id_token;
        this.props.loginWithAppleIdentityToken(identityToken)
    }

    render() {
        return <div>Loading...</div>
    }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = {
    loginWithAppleIdentityToken
};

export default connect(mapStateToProps, mapDispatchToProps)(AppleSigninContainer);