import * as actions from './pushHubActions';

const initialState = {
    message: '',
    loading: false,
    impactedUsers: 0
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case actions.UPDATE_PUSH_MESSAGE:
            return {
                ...state,
                message: action.message
            };

        case actions.SET_LOADING:
            return {
                ...state,
                loading: action.loading
            };

        case actions.SET_USERS_IMPACTED:
            return {
                ...state,
                impactedUsers: action.count
            }

        default:
            return state;
    }
}
