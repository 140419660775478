import * as actions from './loginActions';

const initialState = {
    loggedIn: false,
    token: '',
    loading: false
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case actions.LOGIN_SUCCESSFUL:
            return {
                ...state,
                accessToken: action.accessToken,
                loggedIn: true,
                loading: false
            };

        case actions.SET_LOADING:
            return {
                ...state,
                loading: action.loading
            };

        default:
            return state;
    }
}
