import React from 'react'
import { Button, Form, Grid, Header, Image, Message, Segment } from 'semantic-ui-react'
import { connect } from "react-redux";
import {push} from "react-router-redux";
import {login} from "./loginActions";


class LoginContainer extends React.Component {
    state = { username: '', password: '' };

    componentDidMount = () => {
        window.AppleID.auth.init({
            clientId : 'com.walksafe.admin',
            scope : 'email name',
            redirectURI : 'https://admin.chaperhome.co.uk/api/auth/apple-signin',
            state : 'some-state',
            nonce : '12345',
            usePopup : false
        })

        window.google.accounts.id.initialize({
            client_id: "1094867770250-m51h7h607tdtb7bf9l9jp1o8j6om0tks.apps.googleusercontent.com",
            login_uri: "https://admin.chaperhome.co.uk/api/auth/google-signin",
            ux_mode: "redirect"
        })

        window.google.accounts.id.renderButton(
            document.getElementById("google-signin"),
            { theme: "outline", size: "large" }
        );
    }

    render() {
        const { login, loading } = this.props;
        const { username, password } = this.state;

        return (
            <Grid textAlign='center' verticalAlign='middle'>
                <Grid.Row>
                    <Grid.Column style={{paddingTop: 100, maxWidth: 450}} verticalAlign='middle'>
                        <Header as='h2' color='teal' textAlign='center'>
                            {/*<Image src='/logo.png' />*/}
                            {' '}Log-in to your account
                        </Header>
                        <Form size='large' loading={loading}>
                            <Segment>
                                <Form.Input
                                    fluid
                                    icon='user'
                                    iconPosition='left'
                                    placeholder='E-mail address'
                                    onChange={event => this.setState({username: event.target.value})}
                                />
                                <Form.Input
                                    fluid
                                    icon='lock'
                                    iconPosition='left'
                                    placeholder='Password'
                                    type='password'
                                    onChange={event => this.setState({password: event.target.value})}
                                />

                                <Button color='teal' fluid size='large' onClick={() => login(username, password)}>Login</Button>
                                <br/>
                                <div id="appleid-signin" data-color="black" data-border="true" data-type="sign in" style={{ height: 44 }}/>
                                <br/>
                                <div id="google-signin" />
                            </Segment>
                        </Form>
                        <Message>
                            No account? This website is not publicly available.  If you think you should have access, talk to the
                            tech department
                        </Message>
                    </Grid.Column>
                </Grid.Row>
            </Grid>)
    }
}

const mapStateToProps = state => ({
    loading: state.auth.loading
});

const mapDispatchToProps = {
    push,
    login
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);