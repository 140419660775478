import React from "react";
import {loginWithGoogleIdentityToken} from "./loginActions";
import {connect} from "react-redux";
import queryString from 'query-string';

class GoogleSigninContainer extends React.Component {
    componentDidMount() {
        const query = queryString.parse(this.props.location.search);
        const identityToken = query.id_token;
        this.props.loginWithGoogleIdentityToken(identityToken)
    }

    render() {
        return <div>Loading...</div>
    }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = {
    loginWithGoogleIdentityToken
};

export default connect(mapStateToProps, mapDispatchToProps)(GoogleSigninContainer);