import React, { Component } from 'react';
import SideNavigation from "../layout/Sidebar";
import {Button, Form, Grid} from "semantic-ui-react";
import {connect} from "react-redux";
import {AppVersion, estimateImpactedUsers, sendPushNotification, updatePushMessage} from "./pushHubActions";

class PushHubContainer extends React.Component {
    state = { appSegment: undefined }

    onSubmitNotification = event => {
        const { sendPushNotification, message } = this.props;
        sendPushNotification(message, this.state.appSegment);
    };

    handleChangeApp = (a, b) => {
        const { estimateImpactedUsers } = this.props;
        console.log(b.value)
        const mapped = b.value === 2 ? 2 : undefined
        this.setState({appSegment: mapped })
        estimateImpactedUsers(mapped)
    }

    render()  {

        const { updatePushMessage, loading, impactedUsers } = this.props;

        return (<SideNavigation>
            <Grid padded>
                <Grid.Row>
                    <Grid.Column>
                        <h1>Push notifications hub</h1>
                        <p>
                            Push notifications to all Chaperhome users can be sent from here
                        </p>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column mobile={12} computer={6}>
                        <Form loading={loading} onSubmit={this.onSubmitNotification}>

                            <Form.TextArea required
                                           onChange={event => updatePushMessage(event.target.value)}
                                           label="Message"
                                           placeholder="Push notification message" />


                            <Form.Select placeholder="Select which app" required options={[
                                { key: 'walksafe', value: AppVersion.WalkSafe, text: 'WalkSafe' },
                                { key: 'walksafeplus', value: AppVersion.WalkSafePlus, text: 'WalkSafe+' }
                            ]} onChange={(a, b) => this.handleChangeApp(a, b)}/>

                            <p>This will be sent to ~{impactedUsers} users</p>

                            <Button>Send notifications</Button>
                        </Form>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </SideNavigation>)
    }
}

const mapStateToProps = state => ({
    message: state.pushHub.message,
    loading: state.pushHub.loading,
    impactedUsers: state.pushHub.impactedUsers
});

const mapDispatchToProps = {
    sendPushNotification,
    updatePushMessage,
    estimateImpactedUsers
};

export default connect(mapStateToProps, mapDispatchToProps)(PushHubContainer);
