import React, { Component } from 'react'
import { withGoogleMap, withScriptjs, GoogleMap, Marker, Polyline, Circle } from "react-google-maps"

class MapContainer extends React.Component {
    render() {

        const mappedLocations = this.props.locations.map(l => ({
            lng: l.longitude,
            lat: l.latitude
        }))

        console.log(mappedLocations)

        if (mappedLocations.length === 0) return <div>Loading...</div>


        return <GoogleMap
            google={this.props.google}
            style={{ width: '100%', height: '1000px'}}
            defaultCenter={{ lng: this.props.destination.longitude, lat: this.props.destination.latitude }}
            defaultZoom={8}
        >
            <Marker
            key={'dsfdsgdgf'}
            label={"Expected end destination"}
            position={{ lat: this.props.destination.latitude, lng: this.props.destination.longitude }} />

            { mappedLocations.map((l, index) => {
                return <Marker
                    key={index}
                    position={l}
                    label={index.toString()} />
            })}

            <Polyline path={mappedLocations} options={{
                strokeColor: "#000",
                strokeOpacity: 0.75,
                strokeWeight: 3
            }}/>

            <Circle center={ {lat: this.props.destination.latitude, lng: this.props.destination.longitude} }
                    radius={200} fillColor="green" fillOpacity={0.4} />
        </GoogleMap>
    }
}

export default withScriptjs(withGoogleMap((MapContainer)))

//
// {
//     apiKey: 'AIzaSyAx9W2KIABeKtxl2kEtfcQRe0oRGPRg2kY'
// }
