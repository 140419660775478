import React, { Component } from 'react'
import { Sidebar, Segment, Button, Menu, Image, Icon, Header } from 'semantic-ui-react'
import styled from 'styled-components';
import {connect} from "react-redux";
import {push} from "react-router-redux";
import axios from "axios";

const FullHeightDiv = styled.div`height:100%`;

const PaddedPusher = styled(Sidebar.Pusher)`
    padding-top:40px;
`;

class SideNavigation extends Component {
    state = { visible: true };

    toggleVisibility = () => this.setState({ visible: !this.state.visible });

    render() {
        const { visible } = this.state;
        return (
            <FullHeightDiv>
                <Sidebar.Pushable>
                    <Sidebar as={Menu} animation='push' direction='top'  width='thin' visible={visible} icon='labeled' inverted>
                        <Menu.Item name='notifications' onClick={() => this.props.push('/pushHub')}>
                            <Icon name='comments' />
                            Push hub
                        </Menu.Item>
                        <Menu.Item name='notifications' onClick={() => this.props.push('/homeSafeDebug')}>
                            <Icon name='bug'  />
                            HomeSafe debug
                        </Menu.Item>
                        <Menu.Item name='userManagement' onClick={() => this.props.push('/userManagement')}>
                            <Icon name='lock'  />
                            Manage users
                        </Menu.Item>
                        <Menu.Item name='sign out' onClick={() => {
                            axios.defaults.headers.post['Authorization'] = ``;
                            axios.defaults.headers.get['Authorization'] = ``;
                            this.props.push('/login')
                        }}>
                            <Icon name='sign out' />
                            Logout
                        </Menu.Item>
                    </Sidebar>
                    <PaddedPusher>
                        {/*<Button onClick={this.toggleVisibility}>Toggle navigation</Button>*/}
                        {this.props.children}
                    </PaddedPusher>
                </Sidebar.Pushable>
            </FullHeightDiv>
        )
    }
}


const mapStateToProps = state => ({

});

const mapDispatchToProps = {
    push
};


export default connect(mapStateToProps, mapDispatchToProps)(SideNavigation);
