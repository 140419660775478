import React from 'react'
import SideNavigation from "../layout/Sidebar";
import {Button, Form, Grid} from "semantic-ui-react";
import {connect} from "react-redux";
import {deleteUser} from "./userManagementActions";


class UserManagementContainer extends React.Component {
    state = { deleted: false }

    onDeleteUser = () => {
        this.props.deleteUser(this.state.email)
            .then(() => this.setState({ deleted: true }))
    }

    render() {
        return <SideNavigation selected="userManagement">
            <Grid padded>
                <Grid.Row>
                    <Grid.Column>
                        <h1>User management</h1>
                        <p>
                            User management functionality below.
                        </p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <h2>Delete account</h2>
                        { !this.state.deleted &&
                        <Form onSubmit={this.onDeleteUser}>

                            <Form.Input required
                                           onChange={event => this.setState({email: event.target.value})}
                                           label="Email"
                                           placeholder="Email to delete" />

                            <Button>Delete</Button>
                        </Form>}
                        { this.state.deleted && <p>Deletion completed</p>}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </SideNavigation>
    }

}


const mapStateToProps = state => ({

});

const mapDispatchToProps = {
    deleteUser
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagementContainer)
